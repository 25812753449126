import React, { PureComponent } from 'react';
import './index.scss';

import axios from 'axios'; // use later
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import queryString from 'query-string';
import uuid from 'uuid';

import TableCards from '../tables/table_cards.js';
import { showPageDataRange } from '../../helpers/common_helper';
import Paginate from '../../helpers/paginate_helper';
import SearchForm from '../../helpers/search_form_helper';

// Mobx
import SearchFormStore from '../../stores/search_form_store';
import CardStore from '../../stores/card_store';

class Index extends PureComponent {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchText: "",
			cardTypeIds: "",
			colorIds: "",
			editionIds: "",
			rarityIds: "",
			cardData: [],
			isLoading: false,
			sortInfo: {},
			paginationInfo: {}
		};
	}	

	componentWillMount() {
		const values = queryString.parse(this.props.location.search);

		this.setState({ 
			searchText: values.search_text,
			cardTypeIds: values.card_type_ids,
			colorIds: values.color_ids,
			editionIds: values.edition_ids,
			rarityIds: values.rarity_ids,
			sortInfo: {
				field: values.sort_column,
				order: values.sort_direction
			},
			paginationInfo: {
				current: values.page
			}
		});

		this.fetchCards(values);
	}	

	componentDidMount() {
		this._isMounted = true;
	}	

	componentWillUnmount() {
		this._isMounted = false;
	}	

	fetchCards = (params={}) => {
		this.setState({ isLoading: true});

		axios.get(`${process.env.REACT_APP_API_BASE_URL}/${'api/products/search'}`,  { params }).then(result => 
			this._isMounted && this.refreshData(result.data)
		);
	}

	refreshData = (result) => {
		const paginationInfo = {
			current: result.meta.current_page,
			total: result.meta.total_pages
		}
		const sortInfo = {
			...this.state.sortInfo
		}

		this.setState({
			cardData: result,
			paginationInfo,
			sortInfo,
			isLoading: false
		});
	}

	handlePaginationClick = (data) => {
		const selectedVal = data.selected+1;
		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds, sortInfo } = this.state;

		this.setState({
			paginationInfo: {
				current: selectedVal
			}
		});

		const params = { 
			search_text: searchText, 
			card_type_ids: cardTypeIds, 
			color_ids: colorIds, 
			edition_ids: editionIds, 
			rarity_ids: rarityIds,
			page: selectedVal,
			sort_column: sortInfo.field,
			sort_direction: sortInfo.order
		};
		this.fetchCards(params);

		const stringifiedParams = queryString.stringify(params);

		this.props.history.push({
		  pathname: '/products',
		  search: '?'+stringifiedParams
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds } = this.state;

		// Reset Paginate and Sort
		this.setState({
			paginationInfo: {},
			sortInfo: {}
		});

		const params = { 
			search_text: searchText, 
			card_type_ids: cardTypeIds, 
			color_ids: colorIds, 
			edition_ids: editionIds, 
			rarity_ids: rarityIds
		};
		this.fetchCards(params);

		const stringifiedParams = queryString.stringify(params);		
		this.props.history.push({
		  pathname: '/products',
		  search: '?'+stringifiedParams
		});
	}

	handleChange = (event) => {
		const { value } = event.target;
		const key = event.target.name;
		this.setState({ [key]: event.target.value });
	}

	handleSortClick = (field, order) => (event) => {
		event.preventDefault();

		this.setState({
			sortInfo: {
				field, order
			}
		});

		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds, paginationInfo } = this.state;
		const params = { 
			search_text: searchText, 
			card_type_ids: cardTypeIds, 
			color_ids: colorIds, 
			edition_ids: editionIds,
			rarity_ids: rarityIds,
			page: paginationInfo.current,
			sort_column: field,
			sort_direction: order,
		};
		this.fetchCards(params);

		const stringifiedParams = queryString.stringify(params);
		this.props.history.push({
		  pathname: '/products',
		  search: '?'+stringifiedParams
		});		
	}

	render() {
		const { cardData, isLoading, paginationInfo, sortInfo } = this.state;
		const { meta } = cardData;

		return (
			<main className="products-content">
				<Row>
					<Col md="12">
						<SearchForm
							searchFormStore={SearchFormStore} 
							handleSubmit={this.handleSubmit} 
							handleChange={this.handleChange}
							searchText={this.state.searchText}
							cardTypeIds={this.state.cardTypeIds}
							colorIds={this.state.colorIds}
							editionIds={this.state.editionIds}
							rarityIds={this.state.rarityIds}
							isLoading={isLoading}
						/>
					</Col>
					<Col md="12" className="search-result-section">
						<div className="search-header">
							<h4>All Search</h4>
							<span>
								{meta ? meta.total_count : 0} Total Items (
										{
											meta && showPageDataRange(meta.current_page_count, paginationInfo.current, meta.per_page)
										}
									)
							</span>
						</div>
						<div className="search-content">
							<div className="table-section">
								<TableCards handleSortClick={this.handleSortClick} sortInfo={sortInfo} cardData={cardData} isLoading={isLoading}/>							
							</div>
							{					
								<Paginate pageClassName="page-item" pageLinkClassName="page-link" handlePageClick={this.handlePaginationClick} forcePage={paginationInfo.current} pageCount={meta && meta.total_pages} />
							}
						</div>
					</Col>
				</Row>
			</main>
		);
	}
}

export default Index;