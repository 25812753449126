import { decorate, observable, configure, action, computed, reaction } from 'mobx';

configure({ enforceActions: 'observed' });

class CommonStore {
  appName = 'ChimneyImp';
  token = window.localStorage.getItem('jwt');

  constructor() {
    // Reaction occurs when token is updated...
    reaction(
      () => this.token,
      (token, reaction) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );
  }

  setToken(token) {
    this.token = token;
  }

  destroyToken() {
    this.setToken(undefined);

    return Promise.resolve();
  }

  get isAuthenticated() {
   return this.token && this.token.length > 0 
  }

}

decorate(CommonStore, {
  appName: observable,
  token: observable,
  setToken: action,
  isAuthenticated: computed,
  destroyToken: action,
})

const commonStore = new CommonStore();

// export default new AuthStore();
export default commonStore;
