import React, { Component } from 'react';

import { Col } from 'react-bootstrap';
import Img from 'react-image';
import { Link } from 'react-router-dom';

import { addDefaultSrc } from '../../helpers/common_helper';

class SetName extends Component {

	render() {
		const { name, slug, products_count, first_picture_url } = this.props;
		return (
			<Col md="4" sm="4" xs="6">
				<Img className="small-icon img-fluid" src={first_picture_url} alt={name}/>
				<Link to={'/set-names/' + slug}>{name}</Link>
				({products_count})
			</Col>
		);
	}
}

export default SetName;