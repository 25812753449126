import React, { Component } from 'react';

import { Row, Col} from 'react-bootstrap';
import { observer, inject } from "mobx-react";
import queryString from 'query-string';

import FlashMessages from '../../helpers/flash_messages';

// import UserLinks from './user_links';

class Confirmation extends Component {

	constructor(props) {
		super(props);

		this.state = {						
			flashMessages: {
				alertType: "",
				messages: "",
			},
		};
	}

	componentWillReceiveProps(props) {
		const { confirmationToken } = queryString.parse(this.props.location.search);

		if (confirmationToken) {
			this.handleConfirmation(confirmationToken);
		}
	}

	componentWillMount() {
		const { confirmationToken } = queryString.parse(this.props.location.search);

		if (confirmationToken) {
			this.handleConfirmation(confirmationToken);
		}
	}

	handleConfirmation = (confirmationToken) => {
		this.props.rootStore.authStore.setConfirmationToken(confirmationToken);

		this.props.rootStore.authStore
			.confirmUser()
			.then(result => {;
				this.setState({
					flashMessages: {
						alertType: "success",
						messages: result.data.messages,
					},
				});
			})	
			.catch(error => {
				this.setState({
					flashMessages: {
						alertType: "danger",
						messages: error.response.data.errors[0].details,
					},
				});
				throw error.response.data.errors[0].details;
			});
	}

	render() {
		const isLoadingConfirmation = this.props.rootStore.authStore.isLoadingConfirmation;
		const { flashMessages } = this.state;

		return(
			<Row>
				<Col md="12">
					{
						isLoadingConfirmation &&
						<div>Please wait...</div>
					}
					{
						!isLoadingConfirmation &&
						<FlashMessages {...flashMessages} />
					}					
				</Col>
			</Row>
		);
	}

}

export default inject("rootStore")(observer(Confirmation));
