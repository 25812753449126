import { decorate, configure, when, runInAction, observable, action, computed } from "mobx";
import { sortBy } from 'lodash';

import apiAgent from '../api/api_agent';

configure({ enforceActions: 'observed' });

class TcgGroupsUiStore {
	tableData = [];
	form = {
		searchText: ''
	}
	sortInfo = {
		field: '',
		order: '',
	};
	isLoading = false;

	constructor() {
		when(
				// once...
				() => !this.isLoading,
				// ... then
				() => this.fetchTcgGroups()
		);    
	}

	get getFilteredTableData() {
		const form = this.form;
		const finalResult = this.tableData.filter((item) =>
    	item.name.toLowerCase().search(form.searchText.toLowerCase()) !== -1
		);

		return this.sortInfo.order.toLowerCase() === 'desc' ? sortBy(finalResult, this.sortInfo.field).reverse() : sortBy(finalResult, this.sortInfo.field);
	}

	fetchTcgGroups = async () => {
		this.isLoading = true
		const resultData = await apiAgent.TcgGroups.getAll();

		runInAction(() => {
			this.isLoading = false
			this.tableData = resultData.data;
		});
	}

	setFormValues(key, value) {
		this.form[key] = value;
	}

	setSortInfo(field, order) {
		this.sortInfo.field = field;
		this.sortInfo.order = order;
	}
}

decorate(TcgGroupsUiStore, {
	tableData: observable,
	form: observable,
	sortInfo: observable,
	setFormValues: action,
	setSortInfo: action,
	getFilteredTableData: computed,
});

export default TcgGroupsUiStore;