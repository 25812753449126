import React, { Component, Fragment } from 'react';
import './users.scss';

import axios from 'axios';
import { Row, Col, Form, Button as BsButton } from 'react-bootstrap';
import { observer, inject } from "mobx-react";

import FlashMessages from '../../helpers/flash_messages';

import UserLinks from './user_links';

class ForgetPassword extends Component {

	constructor(props) {
		super(props);

		this.state = {
			formFields : {
				email: '',
			},
			errorDetails: {},
			successDetails: {},
			isCreated: false,
		};
	}

	handleSubmit = (event) => {
		event.preventDefault();

		const params = {
			user: {
				email: this.state.formFields.email,
			}
		};
		this.postRegister(params);
	}

	postRegister = (params={}) => {
		axios.post(process.env.REACT_APP_API_BASE_URL+'/api/users/password', params).then(result => 
		{	
			this.setState({ isCreated: true });
			this.props.rootStore.forgetPasswordStore.setFlashMessages({ alertType: 'success', messages: result.data.messages });
		}).catch(error => {
    	this.setState({ isCreated: false });
    	this.props.rootStore.forgetPasswordStore.setFlashMessages({ alertType: 'danger', messages: error.response.data.errors[0].details });			
    });
	}

  handleChange = (event) => {
  	const { name, value } = event.target;

		const { formFields } = this.state;
		formFields[name] = value;

		this.setState(formFields);
  }	

  showForm = () => (
  	<Fragment>
  	{
  		!this.state.isCreated && 
			<Form className="form-login" onSubmit={this.handleSubmit}>
			  <Form.Group>
			    <Form.Label>Email address</Form.Label>
			    <Form.Control name="email" type="email" placeholder="Enter email" value={this.state.formFields.email} onChange={this.handleChange} />
			    <Form.Text className="text-muted">
			      We'll never share your email with anyone else.
			    </Form.Text>
			  </Form.Group>
				<BsButton variant="success" type="submit">
					Request Password Instruction
				</BsButton>
			</Form>
  	} 	
  	</Fragment>
  );

	render() {
		const { formFields, errorDetails, successDetails } = this.state;

		return (
			<main className="users-registration-content">
				<Row className="page-header">
					<Col className="col-md-12">
						<h1 className="page-title-center">Forget Password</h1>
					</Col>
				</Row>
				<Row className="justify-content-center">			
					<Col md="4">
						<FlashMessages {...this.props.rootStore.forgetPasswordStore.getFlashMessages}/>
						{ this.showForm() }
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="4">
						<UserLinks/>
					</Col>
				</Row>
			</main>
		);
	}
}

export default inject('commonStore', 'rootStore')(observer(ForgetPassword));
