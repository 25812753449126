import React, { PureComponent } from 'react';
import './index.scss';

import { Row, Col } from 'react-bootstrap';

class AboutUs extends PureComponent {

	render() {
		return (
			<main className="about-us-content">
				<Row className="page-header">
					<Col className="col-md-12">
						<h1 className="page-title-center">We Empower Players</h1>
						<h2 className="page-sub-title-center">ChimneyImp gives you the prices and tools to succeed</h2>
					</Col>
				</Row>

				<Row className="justify-content-md-center faq-section">
					<Col className="col-md-10">
						<div className="faq-item">
							<h3>What is ChimneyImp?</h3>
							<p>ChimneyImp is the place where millions of "Magic The Gathering" players get in depth statistics, share games, their profiles, and better understand the game we all love. We turn raw statistical data from billions of public matches into actionable information that you can use to learn and improve. ChimneyImp is widely seen as one of the leading "Magic The Gathering" community resources for both new and experienced players.</p>
						</div>
						<div className="faq-item">
							<h3>How does it work?</h3>
							<p>We track every public game played in "Magic The Gathering". Based on that information, combined with billions of additional data points gathered from replays, Steam and other community resources, we present unique and personal statistics for every "Magic The Gathering" player. You can find your "Magic The Gathering" player profile by signing in with Steam or searching for your name or Steam ID. </p>
							<p>Hardcore players can upgrade their account to ChimneyImp Plus for even more in depth statistics and additional features.</p>
						</div>
						<div className="faq-item">
							<h3>Who’s behind ChimneyImp?</h3>
							<p>ChimneyImp is owned by passionate engineer with a mission to provide gamers with the tools they need to measure and improve their gameplay. We share your passion for "Magic The Gathering" and pride ourselves on developing ChimneyImp in a way that supports and improves the community.</p>
							<p>Our team is pretty small. We have team members in Singapore only.			</p>
						</div>
						<div className="faq-item">
							<h3>How can I help?</h3>
							<p>ChimneyImp is a one man show meaning I am funding everything from my own pocket. I don't have intention to monetize it yet but I do appreciate if you guys can donate some money to feed the server and services cost. Head on to the <a href="/donate">donate page</a> and show me some love :).
							</p>
						</div>						
					</Col>
				</Row>
			</main>
		);
	}
}

export default AboutUs;