import React, { PureComponent } from 'react';
import './index.scss';

import { Row, Col } from 'react-bootstrap';

import SetNames from './set_names';

class Index extends PureComponent {
	render() {
		return (
			<main className="set-names-content">
	      <Row className="page-header">
	        <Col md="12">
	          <h1 className="page-title-center">Set Names</h1>
	        </Col>
	      </Row>		
	      <SetNames/>	
			</main>
		);
	}
}

export default Index;