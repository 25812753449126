import { decorate, observable, configure, runInAction, when } from "mobx";

import apiAgent from '../api/api_agent';

configure({ enforceActions: 'observed' });

class HomeStore {
	latestCardsData = [];
	isLoadingLatestCards = false;

	topGainersData = [];
	isLoadingTopGainers = false;

	constructor() {
		when(
				// once...
				() => !this.isLoadingLatestCards,
				// ... then
				() => this.fetchLatestCards()
		);
		when(
				() => !this.isLoadingTopGainers,
				() => this.fetchGainers()
		);		
	}

	
	fetchLatestCards = async() => {
		this.isLoadingLatestCards = true;
		const resultData = await apiAgent.Products.getLatest();		

		runInAction(() => {
			this.isLoadingLatestCards = false;
			this.latestCardsData = resultData.data
		});
	}

	fetchGainers = async() => {
		this.isLoadingTopGainers = true;
		const resultData = await apiAgent.Products.getTopGainers();

		runInAction(() => {
			this.isLoadingTopGainers = false;
			this.topGainersData = resultData.data
		});
	}

}

decorate(HomeStore, {
	latestCardsData: observable,
	isLoadingLatestCards: observable,
	topGainersData: observable,
	isLoadingTopGainers: observable,
})

export default HomeStore;