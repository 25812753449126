import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const UserLinks = () => (
	<Fragment>
		<br/>
		<br/>
		<ul>
			<li><Link to="/users/sign-in">Sign In</Link></li>		
			<li><Link to="/users/sign-up">Sign Up</Link></li>		
			<li><Link to="/users/forget-password">Forget Password</Link></li>		
		</ul>
	</Fragment>
);

export default UserLinks;