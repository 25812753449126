import { decorate, configure, observable, action, reaction, runInAction } from "mobx";

import apiAgent from '../api/api_agent';

configure({ enforceActions: 'observed' });

class ProductShowUiStore {
	productId = null;
	
	productData = {};
	isLoading = false;

	recommendedProductData = {};
	isLoadingRecommended = false;

	constructor() {
    reaction(
      () => this.productId,
      (productId, reaction) => {
        if (productId) {
        	this.fetchProduct();
        	this.fetchRecommendedProducts();
        }
      }
    );		
	}

	setProductId(productId) {
		this.productId = productId;
	}

	fetchProduct = async() => {
		this.isLoading = true;
		const resultData = await apiAgent.Products.show(this.productId);

		runInAction(() => {
			this.isLoading = false;
			this.productData = resultData.data;
		});
	}

	fetchRecommendedProducts = async() => {
		this.isLoadingRecommended = true;
		const resultData = await apiAgent.Products.getRecommended(this.productId);

		runInAction(() => {
			this.isLoadingRecommended = false;
			this.recommendedProductData = resultData.data;
		});
	}

}

decorate(ProductShowUiStore, {
	productId: observable,
	productData: observable,
	isLoading: observable,	
	recommendedProductData: observable,
	isLoadingRecommended: observable,
	setProductId: action, 
});

export default ProductShowUiStore;
