import React, { PureComponent, Fragment } from 'react';
import uuid from 'uuid';

class FlashMessages extends PureComponent {
	render() {
		const { messages, alertType, additionalClasses} = this.props;

		return (
			<Fragment>
			{
				(messages && messages.length > 0) && 
		    <div className={`alert alert-${alertType || "info"} ${additionalClasses}`} > 
		    	<ul style={{listStyleType: 'none', paddingLeft: '15px', marginBottom: '0px'}}>
		    		{
		    			(Array.isArray(messages) ? messages : [messages]).map((value) =>
		    				<li key={uuid.v4()}>{value}</li>
		    			)
		    		}
		    	</ul>
		    </div>
			}
			</Fragment>			
		);
	}
}

export default FlashMessages;