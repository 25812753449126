import React, { PureComponent } from 'react';
import './table_cards.scss';

import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import uuid from 'uuid';
import { Table as BsTable, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'react-image';
import { Manager, Reference, Popper } from 'react-popper';

const renderTooltip = props =>
  <div
    {...props}
    style={{
      backgroundColor: '#35354a',
      padding: '2px 10px',
      color: 'white',
      borderRadius: 3,
      width: '220px',
      ...props.style,
    }}
  >
  	<Img className="img-fluid" src={props.first_picture_url} alt={props.name}/>
  </div>

const popover = (first_picture_url, name) => (
  <Popover title="Popover right">
    And here's some <strong>amazing</strong> content. It's very engaging. right?
  </Popover>
);

const ManagerPopper = (props) => (
  <Manager>
    <Reference>
      {({ ref }) => (
      	<Link to={props.to} ref={ref}>{props.name}</Link>
      )}
    </Reference>
    <Popper placement="left">
      {({ ref, style, placement, arrowProps }) => (
        <div ref={ref} style={style} data-placement={placement}>
          Popper element
          <div ref={arrowProps.ref} style={arrowProps.style} />
        </div>
      )}
    </Popper>
  </Manager>
);

const ShowImage = ({id, firstPictureUrl, style, altName}) => {
	return (
		<Img id={id} className="img-fluid" style={style} src={firstPictureUrl} alt={altName}/>
	);
};

// class LinkWithImage extends PureComponent {
// 	constructor() {
// 		super(props)
// 	}

// 	link = ({to, value}) => (
// 		<Link
// 			to={to}>{ReactHtmlParser(name + '&nbsp;&raquo;')}
// 		</Link>		
// 	);

// 	showImage = ({firstPictureUrl, altName}) => (
// 		<Img className="img-fluid" src={firstPictureUrl} alt={altName}/>
// 	);

// 	render() {
// 		const { firstPictureUrl, altName } = this.props;

// 		return (
// 			<Fragment>
// 				{this.showImage({firstPictureUrl, altName})}
// 			</Fragment>
// 		);
// 	}
// }

class TableCards extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			displayImg: ''
		}
	}

	componentWillMount() {
	}

	tableHeaderRows = ({handleSortClick, field, order}) => {
		return(
			<tr>
				<th className={"blue" + (handleSortClick ? " sortableHeader" : "")} onClick={handleSortClick && handleSortClick('name', order === 'asc' ? 'desc' : 'asc')}> 
					 Name&nbsp;&nbsp;
					{
						handleSortClick &&
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "name" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>						
					}
				</th>
				<th className={"green" + (handleSortClick ? " sortableHeader" : "")} onClick={handleSortClick && handleSortClick('set_name', order === 'asc' ? 'desc' : 'asc')}>
					Set Name&nbsp;&nbsp;
					{
						handleSortClick &&
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "set_name" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className={"orange" + (handleSortClick ? " sortableHeader" : "")} onClick={handleSortClick && handleSortClick('rarity', order === 'asc' ? 'desc' : 'asc')}>
					Rarity&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "rarity" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className={(handleSortClick ? " sortableHeader" : "")} onClick={handleSortClick &&  handleSortClick('price', order === 'asc' ? 'desc' : 'asc')}>
					Price&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "price" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className={(handleSortClick ? " sortableHeader" : "")} onClick={handleSortClick &&  handleSortClick('foil_price', order === 'asc' ? 'desc' : 'asc')}>
					Foil Price&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "foil_price" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
			</tr>
		);		
	}

	linkOnMouseOut = (event) => {
		// this.setState({
		// 	displayImg: ''
		// });
	}

	linkOnMouseOver = (event) => {
		this.setState({
			displayImg: event.currentTarget.dataset.targetimg
		});
	}

 	tableCardRows = ({displayImg, id, slug, name, set_name, set_name_slug, rarity, latest_price, latest_foil_price, first_picture_url}) => {
		return (
			<tr key={id}>	
				<td data-targetimg={'link-image-'+id}
						onMouseOver={this.linkOnMouseOver} 
						onMouseOut={this.linkOnMouseOut}>
					<Link
						to={'/products/' + slug}>{ReactHtmlParser(name + '&nbsp;&raquo;')}
					</Link>
					<br/>
					<ShowImage style={{marginTop: '10px', display: (displayImg && displayImg === ("link-image-"+id) ? "block" : "none" )  }} firstPictureUrl={first_picture_url} altName={name}/>					
				</td>
				<td>
					<Link to={'/set-names/' + set_name_slug} className="green">{ReactHtmlParser(set_name + '&nbsp;&raquo;')}</Link>
				</td>
				<td className="orange">{rarity}</td>
				<td className="normal-price"><NumberFormat value={latest_price} displayType={'text'} thousandSeparator={true} prefix={'$'}/></td>
				<td className="foil-price"><NumberFormat value={latest_foil_price} displayType={'text'} thousandSeparator={true} prefix={'$'}/></td>
			</tr>
		);
	}	

					// { false &&
					// 	<OverlayTrigger modifiers={{ preventOverflow: { enabled: false }}} trigger="hover" placement="bottom" overlay={popover(first_picture_url, name)}>
					// 		<Link to={'/products/' + slug}>{ReactHtmlParser(name + '&nbsp;&raquo;')}</Link>
					// 	</OverlayTrigger>
					// }

	render() {
		const { cardData, isLoading, handleSortClick } = this.props;
		const { displayImg } = this.state;
		const cardDataSet = cardData.data;

		const tableClassNames = "table-price" + (isLoading ? " loading" : "");

		return (
			<div className="table-responsive">						
				<BsTable className={tableClassNames}>
					<thead>
						{this.tableHeaderRows({handleSortClick, ...this.props.sortInfo})}
					</thead>	
					<tbody>
						{
							(!isLoading && (cardDataSet || []).length === 0) && 
							<tr>
								<td colSpan="5" className="text-center">No Card Data</td>
							</tr>							
						}
						{
							(cardDataSet || []).map((item, index) => 
								this.tableCardRows({displayImg, ...item.attributes })
							)
						}
					</tbody>
				</BsTable>
			</div>
		);
	}
}

export default TableCards;