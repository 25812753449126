import React, { Component } from 'react';
import './index.scss';

import { Row } from 'react-bootstrap';
import { inject, observer } from "mobx-react";

import SetName from './set_name';

class SetNames extends Component {
	render() {
		const { rootStore: { setNameStore: { setNamesData, isLoading } } } = this.props;

		return (
			<Row className="magic-editions">			
				{
					!isLoading &&
					(setNamesData || []).map((item, index) =>
						<SetName key={item.id} name={item.name} slug={item.slug} products_count={item.products_count}/>
					)
				}
			</Row>
		);
	}
}

export default inject('rootStore')(observer(SetNames));