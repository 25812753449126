import React, { Component } from 'react';
import './paginate_helper.scss';

import ReactPaginate from 'react-paginate';
import uuid from 'uuid';

class Paginate extends Component {

	render() {
		const { previousLabel, nextLabel, 
			breakLabel, breakClassName, pageCount, marginPagesDisplayed,
			pageRangeDisplayed, handlePageClick, containerClassName, 
			pagination, activeClassName,
			initialPage, forcePage, pageClassName, pageLinkClassName
		 } = this.props;

		// ForcePage won't cause onPageChange to run when on initial rendering
		// InitialPage will cause onPageChange to run on initial rendering  
		// initialPage={initialPage ? (initialPage-1) : 0}
		return (
			<div className="paginate-section">
				<ReactPaginate
					// initialPage={initialPage ? (initialPage-1) : 0}
					forcePage={forcePage ? (forcePage-1) : 0}
				  previousLabel={previousLabel}
				  nextLabel={nextLabel}
				  breakLabel={breakLabel}
				  breakClassName={breakClassName}
				  pageCount={pageCount}
				  marginPagesDisplayed={marginPagesDisplayed}
				  pageRangeDisplayed={pageRangeDisplayed || 5}
				  onPageChange={handlePageClick}
				  containerClassName={containerClassName || 'pagination'}
				  activeClassName={activeClassName || 'active'}
				  previousClassName={pageClassName}
				  previousLinkClassName={pageLinkClassName}
				  pageClassName={pageClassName}
				  pageLinkClassName={pageLinkClassName}
				  nextClassName={pageClassName}
				  nextLinkClassName={pageLinkClassName}
				/>			
			</div>
		);
	}

			// <ReactPaginate
			//   previousLabel={'previous'}
			//   nextLabel={'next'}
			//   breakLabel={'...'}
			//   breakClassName={'break-me'}
			//   pageCount={this.state.pageCount}
			//   marginPagesDisplayed={2}
			//   pageRangeDisplayed={5}
			//   onPageChange={this.handlePageClick}
			//   containerClassName={'pagination'}
			//   subContainerClassName={'pages pagination'}
			//   activeClassName={'active'}
			// />			

	// render() {
	// 	const { parentOnClick } = this.props;

	// 	return (
	// 		<Pagination>
	// 		  <Pagination.First selected="gg.com" onClick={parentOnClick}/>
	// 		  <Pagination.Prev />
	// 		  <Pagination.Item>{1}</Pagination.Item>
	// 		  <Pagination.Ellipsis />

	// 		  <Pagination.Item>{10}</Pagination.Item>
	// 		  <Pagination.Item>{11}</Pagination.Item>
	// 		  <Pagination.Item active>{12}</Pagination.Item>
	// 		  <Pagination.Item>{13}</Pagination.Item>
	// 		  <Pagination.Item disabled>{14}</Pagination.Item>

	// 		  <Pagination.Ellipsis />
	// 		  <Pagination.Item>{20}</Pagination.Item>
	// 		  <Pagination.Next />
	// 		  <Pagination.Last />
	// 		</Pagination>
	// 	);
	// }
}

export default Paginate;