import React, { Component } from "react";
import "./index.scss";

import { Button as BsButton, Form, Row, Col } from "react-bootstrap";
import { observer, inject } from "mobx-react";

import TableCards from "../tables/table_cards.js";

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = { searchText: "" };
	}

	handleSubmit = event => {
		event.preventDefault();
		const { searchText } = this.state;
		this.props.history.push({
			pathname: "/products",
			search: "?search_text=" + searchText,
		});
	};

	handleChange = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	render() {
		const {
			rootStore: {
				homeStore: {
					latestCardsData,
					isLoadingLatestCards,
					topGainersData,
					isLoadingTopGainers,
				},
			},
		} = this.props;

		return (
			<main className="home-content">
				<Row>
					<Col md="12">
						<h1 className="main-title">React JS</h1>
						<h2 className="sec-title">
							Online MTG card database and price tracker
						</h2>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="6">
						<Form
							className="form-search text-center"
							onSubmit={this.handleSubmit}
						>
							<Form.Group controlId="formBasicEmail">
								<Form.Control
									name="searchText"
									type="text"
									placeholder="Search Magic Card Names"
									value={this.state.searchText}
									onChange={this.handleChange}
								/>
							</Form.Group>
							<BsButton variant="outline-success" type="submit">
								Search
							</BsButton>
						</Form>
					</Col>
				</Row>
				<Row className="table-section">
					<Col md="12">
						<span className="title">Latest Cards</span>
						<TableCards
							cardData={latestCardsData}
							isLoading={isLoadingLatestCards}
						/>
					</Col>
				</Row>
				<Row className="table-section">
					<Col md="12">
						<span className="title">Top Gainers</span>
						<TableCards
							cardData={topGainersData}
							isLoading={isLoadingTopGainers}
						/>
					</Col>
				</Row>
			</main>
		);
	}
}

export default inject("rootStore")(observer(Index));
