import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import "./product_show.scss";

import { Col, Row } from "react-bootstrap";
import Img from "react-image";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const ProductOtherSection = ({
	handleRecommendedLinkClick,
	recommendedProductData,
	isLoadingRecommended,
}) => {
	return (
		<div className="product-others-section">
			<Row>
				<Col md="12">
					<h4>Recommended</h4>
				</Col>
				{!isLoadingRecommended &&
					recommendedProductData.data.map((item, index) => (
						<Col key={item.id} xs="6" sm="3" md="2">
							<Link to={"/products/" + item.attributes.slug}>
								<Img
									className="img-fluid"
									src={item.attributes.first_picture_url}
									alt={item.attributes.name}
								/>
								<span className="small-item-name">{item.attributes.name}</span>
								<span className="small-item-price">
									<NumberFormat
										value={item.attributes.latest_price}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"$"}
									/>
								</span>
							</Link>
						</Col>
					))}
			</Row>
		</div>
	);
};

class ProductShow extends Component {
	_isMounted = false;

	componentWillReceiveProps(props) {
		const { match, rootStore } = props;
		if (match.params.id) {
			rootStore.productShowUiStore.setProductId(match.params.id);
		}
	}

	componentWillMount() {
		const { match, rootStore } = this.props;
		rootStore.productShowUiStore.setProductId(match.params.id);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleRecommendedLinkClick = event => {
		event.preventDefault();
		this.props.history.push({
			pathname: event.target.parentElement.getAttribute("href"),
		});
	};

	render() {
		const {
			productData,
			recommendedProductData,
			isLoadingRecommended,
		} = this.props.rootStore.productShowUiStore;
		const { attributes } = productData.data || {};
		const { included } = productData || {};

		return (
			<Fragment>
				<div className="product-section">
					<Row>
						<Col md="3">
							<h3 className="product-title">{attributes && attributes.name}</h3>
							<div className="main-img-holder">
								<Img
									className="main-image img-fluid"
									src={attributes && attributes.first_picture_url}
									alt={attributes && attributes.name}
								/>
							</div>
							<p className="product-description">
								{attributes && attributes.description}
							</p>
							<span className="artist">
								{attributes && `By ${attributes.artist}`}
							</span>
							<hr />
							<div className="variant-section">
								{(included || []).map((item, key) => (
									<div key={key} className="variant-item">
										<span className="left">
											{item.attributes.parent_variation_name} :{" "}
										</span>
										<span className="right">{item.attributes.name}</span>
									</div>
								))}
							</div>
						</Col>
						<Col md="9">
							<h5>
								Price:{" "}
								<NumberFormat
									value={attributes && attributes.latest_price}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"$"}
								/>
							</h5>
							<h5>
								Foil Price:{" "}
								<NumberFormat
									value={attributes && attributes.latest_foil_price}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"$"}
								/>
							</h5>
							Price Last Updated at{" "}
							<Moment
								format="MMM DD, YYYY - H:mmA"
								date={
									attributes &&
									(attributes.price_updated_at || attributes.updated_at)
								}
							/>
						</Col>
					</Row>
				</div>
				<ProductOtherSection
					handleRecommendedLinkClick={this.handleRecommendedLinkClick}
					recommendedProductData={recommendedProductData}
					isLoadingRecommended={isLoadingRecommended}
				/>
			</Fragment>
		);
	}
}

export default inject("rootStore")(observer(ProductShow));
