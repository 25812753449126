import DashboardStore from './dashboard_store';
import SignInStore from './sign_in_store';
import SignUpStore from './sign_up_store';
import ForgetPasswordStore from './forget_password_store';

import SetNameStore from './set_name_store';
import HomeStore from './home_store';
import AuthStore from './auth_store';

import TcgGroupsUiStore from './tcg_groups_ui_store';
import ProductShowUiStore from './product_show_ui_store';

class RootStore {
	constructor() {
		this.dashboardStore = new DashboardStore();
		this.signInStore = new SignInStore();
		this.signUpStore = new SignUpStore();
		this.forgetPasswordStore = new ForgetPasswordStore();
		this.setNameStore = new SetNameStore();
		this.homeStore = new HomeStore();
		this.authStore = new AuthStore();

		this.tcgGroupsUiStore = new TcgGroupsUiStore();
		this.productShowUiStore = new ProductShowUiStore();
	}
}

const rootStore = new RootStore();

export default rootStore;