import React, { PureComponent } from 'react';
import './table_cards.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table as BsTable, Badge as BsBadge } from 'react-bootstrap';

// # {"groupId"=>2422, "name"=>"Modern Horizons", "abbreviation"=>"MH1", "isSupplemental"=>true, "publishedOn"=>"2019-06-14T00:00:00", "modifiedOn"=>"2019-03-01T16:01:07.317", "categoryId"=>1}

const BadgeTrueFalse = ({...restProps}) => {
	const { badgevalue } = restProps;
	const children = badgevalue === 'true' ? 'True' : 'False' 
	const variant = badgevalue === 'false' ? 'info' : 'danger'

	return (
		<BsBadge variant={variant} {...restProps}>
			{children}
		</BsBadge>
	);
}

class TableTcgGroups extends PureComponent {

	tableHeaderRows = ({handleSortClick, field, order}) => {
		return(
			<tr>
				<th className="blue sortableHeader" onClick={handleSortClick && handleSortClick('tcg_group_id', order === 'asc' ? 'desc' : 'asc')}> 
					 Group ID&nbsp;&nbsp;
					{
						handleSortClick &&
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "tcg_group_id" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>						
					}
				</th>
				<th className="green sortableHeader" onClick={handleSortClick && handleSortClick('name', order === 'asc' ? 'desc' : 'asc')}>
					Name&nbsp;&nbsp;
					{
						handleSortClick &&
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "name" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className="orange sortableHeader" onClick={handleSortClick && handleSortClick('abbreviation', order === 'asc' ? 'desc' : 'asc')}>
					Abbreviation&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "abbreviation" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className="blue sortableHeader" onClick={handleSortClick &&  handleSortClick('is_supplemental', order === 'asc' ? 'desc' : 'asc')}>
					Is Supplemental&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "is_supplemental" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
				<th className="green sortableHeader" onClick={handleSortClick &&  handleSortClick('tcg_category_id', order === 'asc' ? 'desc' : 'asc')}>
					Category ID&nbsp;&nbsp;
					{
						handleSortClick &&					
						<FontAwesomeIcon icon={ 
							field && field.toLowerCase() === "tcg_category_id" ? (order.toLowerCase() === "asc" ? "long-arrow-alt-up" : "long-arrow-alt-down" ) : "arrows-alt-v" 
						} 
						/>					
					}
				</th>
			</tr>
		);		
	}

 	tableCardRows = ({id, tcg_group_id, name, abbreviation, is_supplemental, tcg_category_id}) => {
		return (
			<tr key={id}>	
				<td className="blue">{tcg_group_id}</td>
				<td className="green">{name}</td>
				<td className="orange">{abbreviation}</td>
				<td className="red">
					<BadgeTrueFalse pill badgevalue={is_supplemental.toString()}/>						
				</td>
				<td className="green">{tcg_category_id}</td>
			</tr>
		);
	}		

	render() {
		const { tableData, isLoading, handleSortClick } = this.props;
		const dataSet = tableData;

		const tableClassNames = "table-price" + (isLoading ? " loading" : "");

		return (
			<div className="table-responsive">						
				<BsTable className={tableClassNames}>
					<thead>
						{this.tableHeaderRows({handleSortClick, ...this.props.sortInfo})}
					</thead>	
					<tbody>
						{
							(!isLoading && (dataSet || []).length === 0) && 
							<tr>
								<td colSpan="5" className="text-center">No Tcg Groups Data</td>
							</tr>							
						}
						{
							(dataSet || []).map((item, index) => 
								this.tableCardRows({...item})
							)
						}
					</tbody>
				</BsTable>
			</div>			
		);
	}
}

export default TableTcgGroups;