import { decorate, observable, configure, runInAction, when } from "mobx";

import apiAgent from '../api/api_agent';

configure({ enforceActions: 'observed' });

class SetNameStore {
	setNamesData = [];
	isLoading = false;

	constructor() {
		when(
				// once...
				() => !this.isLoading,
				// ... then
				() => this.fetchSetNames()
		);    
	}

	fetchSetNames = async () => {
		this.isLoading = true
		const resultData = await apiAgent.SetNames.get();

		runInAction(() => {
			this.isLoading = false
			this.setNamesData = resultData;
		});
	}
}

decorate(SetNameStore, {
	setNamesData: observable,
	isLoading: observable,
})

export default SetNameStore;