import { decorate, observable, configure, action, computed } from "mobx";

configure({ enforceActions: 'observed' });

class SignUpStore {
	flashMessages = {};

	setFlashMessages({ messages, alertType, classNames }) {
		this.flashMessages = { messages, alertType, classNames };		
	}

	get getFlashMessages() {		
		return { ...this.flashMessages };
	}

	clearFlashMessages() {
		this.flashMessages = {};
	}
}

decorate(SignUpStore, {
	flashMessages: observable,
	setFlashMessages: action,	
	getFlashMessages: computed,
	clearFlashMessages: action,
})

export default SignUpStore;