import React from 'react';
import { Button as BsButton } from 'react-bootstrap';

function addDefaultSrc(ev){
  ev.target.src = process.env.PUBLIC_URL + "images/set_names/dot.png";
}

function showPageDataRange(currentPageCount, currentPage, perPage) {
	let text = ""

	if(currentPage === 1) {
		text = "Showing 1 - " + (currentPageCount * currentPage)
	} else {
		let startingCount = perPage * ( currentPage - 1 ) + 1;
		let endingCount = startingCount - 1 + currentPageCount;

		text = "Showing " + startingCount + " to " + endingCount;
	}
	return text;
}

const ButtonWithLoading = ({ isLoading, children, ...restProps }) => {
	return (
		<BsButton disabled={isLoading} { ...restProps } >
			{ isLoading ? 'Please wait ...' : children }
		</BsButton>
	);
}

export {
	addDefaultSrc,
	showPageDataRange,
	ButtonWithLoading
};