import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

import './error_pages.scss';

class Page404 extends PureComponent {

	render() {
		return (
			<Row>
				<Col md="12 text-center">
					No match for <code>{this.props.location.pathname}</code>
				</Col>
			</Row>			
		)
	}
}

export default Page404;