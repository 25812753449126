import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './index.scss';

import { Row, Col, Form } from 'react-bootstrap';
import TableTcgGroups from '../tables/table_tcg_groups.js';

class Index extends Component {

	handleSortClick = (field, order) => (event) => {
		event.preventDefault();
		this.props.rootStore.tcgGroupsUiStore.setSortInfo(field, order);
	}

	handleSubmit = (event)  => {
		event.preventDefault();
	}

	handleChange = (event) => {
		const { value } = event.target;
		const key = event.target.name;
		this.props.rootStore.tcgGroupsUiStore.setFormValues(key, value);
	}

	render() {
		const { rootStore } = this.props;

		return (
			<main className="products-content">
				<Row className="justify-content-center">
					<Col md="4" className="search-result-section">
						{process.env.API_BASE_URL}
						<Form className="form-main-search" onSubmit={this.handleSubmit}>
							<Form.Group>
								<Form.Control name="searchText" type="text" autoComplete="off" placeholder="Search by Names" value={rootStore.tcgGroupsUiStore.form.searchText} onChange={this.handleChange}/>
							</Form.Group>												
							</Form>
							<div className="search-header"><h4>TCG Groups</h4><span>{rootStore.tcgGroupsUiStore.getFilteredTableData.length} Total Items</span></div>
					</Col>
					<Col md="12">
						<div className="search-content">
							<div className="table-section">
								<TableTcgGroups handleSortClick={this.handleSortClick} sortInfo={rootStore.tcgGroupsUiStore.sortInfo} isLoading={rootStore.tcgGroupsUiStore.isLoading} tableData={rootStore.tcgGroupsUiStore.getFilteredTableData}/>
							</div>
						</div>
					</Col>
				</Row>
			</main>
		);
	}
}

export default inject('rootStore')(observer(Index));