import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react';

// Global Stores
import commonStore from './stores/common_store';

// UI Stores
import rootStore from './stores/root_store';

const stores = {
	// Global Stores
	commonStore,
	// UI Stores
	rootStore,
}

ReactDOM.render(
	(
		<Provider {...stores}>
			<App/>
		</Provider>
	),
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();