import React, { Component } from 'react';
import './index.scss';

import { Row, Col } from 'react-bootstrap';
import { inject, observer } from "mobx-react";

import FlashMessages from '../../../helpers/flash_messages';

class Index extends Component {

	render() {
		return (
			<main className="about-us-content">
				<Row className="page-header">
				</Row>

				<FlashMessages {...this.props.rootStore.dashboardStore.getFlashMessages}/>

				<Row className="justify-content-md-center faq-section">
					<Col className="col-md-10">
						Hi Sir, you are login!!!
					</Col>
				</Row>
			</main>
		);
	}
}

export default inject('rootStore')(observer(Index));