import {
	decorate,
	observable,
	configure,
	action,
	runInAction,
	when,
} from "mobx";
import axios from "axios";

configure({ enforceActions: "observed" });

class CardStore {
	cardsData = [];
	paginationInfo = {};
	sortInfo = {};

	isLoading = false;

	constructor() {
		when(
			// once...
			() => !this.isLoading,
			// ... then
			() => this.fetchCards()
		);
	}

	fetchCards(params = {}) {
		this.cardsData = [];

		axios
			.get(process.env.REACT_APP_API_BASE_URL + "/api/products/search", {
				params,
			})
			.then(result => {
				runInAction(() => {
					this.cardsData = result.data;
					this.isLoading = true;
				});
			});
	}
}

decorate(CardStore, {
	cardsData: observable,
	fetchCards: action,
});

const cardStore = new CardStore();

export default cardStore;
