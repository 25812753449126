import { decorate, observable, configure, runInAction, when } from "mobx";
import axios from 'axios';

configure({ enforceActions: 'observed' });

class SearchFormStore {
	searchFormAttributes = [];
	isLoading = false;

	constructor() {
		when(
				// once...
				() => !this.isLoading,
				// ... then
				() => this.fetchFormAttributes()
		);    
	}

	fetchFormAttributes() {
		this.searchFormAttributes = [];

		axios.get(`${process.env.REACT_APP_API_BASE_URL}/${'api/products/search_form_attributes'}`).then(result => 
		{
			runInAction(() => {
				this.searchFormAttributes = result.data;
				this.isLoading = true;
			});
		});
	}	

}

decorate(SearchFormStore, {
	searchFormAttributes: observable
})

export default new SearchFormStore();