import React, { Component } from "react";
import "./users.scss";

import { Row, Col, Form } from "react-bootstrap";
import { observer, inject } from "mobx-react";
import { Formik, Field as FormikField, ErrorMessage } from "formik";
import _ from "lodash";
import * as yup from "yup";

import FlashMessages from "../../helpers/flash_messages";
import { ButtonWithLoading } from "../../helpers/common_helper";

import UserLinks from "./user_links";

const validationSchema = yup.object({
	email: yup
		.string()
		.required()
		.email(),
	password: yup.string().required(),
	passwordConfirmation: yup.string().required(),
});

const SignUpForm = props => {
	const {
		isSubmitting,
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
	} = props;

	return (
		<Form className="form-login" onSubmit={handleSubmit}>
			<Form.Group>
				<Form.Label>Email address</Form.Label>
				<Form.Control
					name="email"
					type="text"
					autoComplete="off"
					placeholder="Enter email"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<Form.Text className="text-muted">
					We will never share your email with anyone else.
				</Form.Text>
				<ErrorMessage
					name="email"
					render={msg => <div className="text-danger">{_.upperFirst(_.toLower(_.startCase(msg)))}</div>}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Password</Form.Label>
				<Form.Control
					name="password"
					type="password"
					autoComplete="off"
					placeholder="Enter password"
					value={values.password}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<ErrorMessage
					name="password"
					render={msg => <div className="text-danger">{_.upperFirst(_.toLower(_.startCase(msg)))}</div>}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Password Confirmation</Form.Label>
				<Form.Control
					name="passwordConfirmation"
					type="password"
					autoComplete="off"
					placeholder="Confirm password"
					value={values.passwordConfirmation}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<ErrorMessage
					name="passwordConfirmation"
					render={msg => <div className="text-danger">{_.upperFirst(_.toLower(_.startCase(msg)))}</div>}
				/>
			</Form.Group>
			<ButtonWithLoading
				variant="success"
				type="submit"
				isLoading={isSubmitting}
			>
				Login
			</ButtonWithLoading>
		</Form>
	);
};

class SignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formFields: {
				email: "",
				password: "",
				passwordConfirmation: "",
			},
			isCreated: false,
			flashMessages: {
				alertType: "",
				messages: "",
			},
		};
	}

	handleSubmit = (values, actions) => {
		this.props.rootStore.authStore.setUserFields("email", values.email);
		this.props.rootStore.authStore.setUserFields("password", values.password);
		this.props.rootStore.authStore.setUserFields("passwordConfirmation", values.passwordConfirmation);

		this.props.rootStore.authStore
			.register()
			.then(result => {
				this.setState({ isCreated: true });
				this.setState({
					flashMessages: {
						alertType: "success",
						messages: result.data.messages,
					},
				});
			})
			.catch(error => {
				this.setState({
					flashMessages: {
						alertType: "danger",
						messages: error.response.data.errors[0].details,
					},
				});
				throw error.response.data.errors[0].details;
			})
			.finally(() => {
				actions.setSubmitting(false);
			});
		return;
	};

	handleChange = event => {
		const { name, value } = event.target;

		const { formFields } = this.state;
		formFields[name] = value;

		this.setState(formFields);
	};

	render() {
		const { formFields, flashMessages } = this.state;

		return (
			<main className="users-registration-content">
				<Row className="page-header">
					<Col className="col-md-12">
						<h1 className="page-title-center">New User Registration</h1>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="4">
						<FlashMessages {...flashMessages} />
						{!this.state.isCreated && (
							<Formik
								initialValues={{
									email: "",
									password: "",
									passwordConfirmation: "",
								}}
								validationSchema={validationSchema}
								onSubmit={this.handleSubmit}
								render={formikProps => <SignUpForm {...formikProps} />}
							/>
						)}
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="4">
						<UserLinks />
					</Col>
				</Row>
			</main>
		);
	}
}

export default inject("rootStore")(observer(SignUp));
