import React, { Component, Fragment } from "react";
import logo from "./logo.svg";
import "./App.scss";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
	BrowserRouter,
	Route,
	Switch,
	Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { observer, inject } from "mobx-react";

import Home from "./components/home/index";
import AboutUs from "./components/about_us/index";
import Donate from "./components/donate/index";
import TcgGroups from "./components/tcg_groups/index";
import SetNames from "./components/set_names/index";
import SetNameShow from "./components/set_names/set_name_show";
import Products from "./components/products/index";
import ProductShow from "./components/products/product_show";
import SignUp from "./components/users/sign_up";
import SignIn from "./components/users/sign_in";
import ForgetPassword from "./components/users/forget_password";
import UserConfirmation from "./components/users/confirmation";
import Dashboard from "./components/user/dashboard";
import Mobx from "./components/mobx/index";
import page404 from "./components/error_pages/page404";

import PrivateRoute from "./private_route";
import SpecialRoute from "./special_route";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowsAltV,
	faLongArrowAltUp,
	faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons";

library.add(faArrowsAltV, faLongArrowAltUp, faLongArrowAltDown);

const ScrollToTop = withRouter(
	class ScrollToTop extends Component {
		componentDidUpdate(prevProps) {
			if (this.props.location.pathname !== prevProps.location.pathname) {
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		}

		render() {
			return this.props.children;
		}
	}
);

const ContentYield = () => (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route exact path="/about-us" component={AboutUs} />
		<Route exact path="/donate" component={Donate} />
		<Route exact path="/tcg-groups" component={TcgGroups} />
		<Route exact path="/set-names" component={SetNames} />
		<Route exact path="/set-names/:id" component={SetNameShow} />
		<Route exact path="/products" component={Products} />
		<Route exact path="/products/:id" component={ProductShow} />
		<Route exact path="/mobx" component={Mobx} />
		<Route exact path="/users/confirmation" component={UserConfirmation} />
		<SpecialRoute exact path="/users/sign-in" component={SignIn} />
		<SpecialRoute exact path="/users/sign-up" component={SignUp} />
		<SpecialRoute
			exact
			path="/users/forget-password"
			component={ForgetPassword}
		/>
		<PrivateRoute path="/dashboard" component={Dashboard} />
		<Route component={page404} />
	</Switch>
);

const NavMenuLink = ({ content, to, activateExact, className, hidden }) => (
	<Fragment>
		{!hidden && (
			<Route
				path={to}
				exact
				children={({ match }) => (
					<li className={"nav-item " + (match ? "active" : "")}>
						<Link className="nav-link" to={to}>
							{content}
						</Link>
					</li>
				)}
			/>
		)}
	</Fragment>
);

const LogoutLink = withRouter(({ match, location, history, ...restProps }) =>
	restProps.commonStore.isAuthenticated ? (
		<li className={"nav-item"}>
			<a
				href="#"
				className="nav-link"
				onClick={e => {
					e.preventDefault();
					restProps.commonStore.destroyToken();
					restProps.rootStore.signInStore.setFlashMessages({
						alertType: "success",
						messages: "Logout successfully!",
					});
					history.push({ pathname: "/users/sign-in" });
				}}
			>
				Logout
			</a>
		</li>
	) : null
);

class App extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<BrowserRouter>
				<ScrollToTop>
					<div className="App">
						<Navbar className="navbar-dark fixed-top bg-dark" expand="lg">
							<Container>
								<Link to="/" className="navbar-brand">
									React
								</Link>
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse id="basic-navbar-nav">
									<ul className="navbar-nav mr-auto">
										<NavMenuLink content="Home" to="/" className="nav-link" />
										<NavMenuLink
											content="About Us"
											to="/about-us"
											className="nav-link"
										/>
										<NavMenuLink
											content="Donate"
											to="/donate"
											className="nav-link"
										/>
										<NavMenuLink
											content="Tcg Groups"
											to="/tcg-groups"
											className="nav-link"
										/>
										<NavMenuLink
											content="Set Names"
											to="/set-names"
											className="nav-link"
										/>
										<NavMenuLink
											content="Sign In"
											to="/users/sign-in"
											className="nav-link"
											hidden={this.props.commonStore.isAuthenticated}
										/>
										<NavMenuLink
											content="Sign Up"
											to="/users/sign-up"
											className="nav-link"
											hidden={this.props.commonStore.isAuthenticated}
										/>
										<NavMenuLink
											content="Dashboard"
											to="/dashboard"
											className="nav-link"
											hidden={!this.props.commonStore.isAuthenticated}
										/>
										<NavMenuLink
											content="My TodoList"
											to="/mobx"
											className="nav-link"
											hidden={!this.props.commonStore.isAuthenticated}
										/>
										<LogoutLink {...this.props} />
									</ul>
								</Navbar.Collapse>
							</Container>
						</Navbar>
						<Container>
							<ContentYield />
						</Container>
					</div>
				</ScrollToTop>
			</BrowserRouter>
		);
	}
}

export default inject("commonStore", "rootStore")(observer(App));
