import React, { Component } from 'react';
import './index.scss';

import axios from 'axios'; // use later
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { decorate, observable, configure, action, computed } from "mobx";
import { observer } from "mobx-react";

import appStore from '../../stores/store.js';

import { showPageDataRange } from '../../helpers/common_helper';

const TableRow = (props) => {
  return (<tr>
    <td>{props.data.name}</td>
    <td>{props.data.salary}</td>
  </tr>)
}

class Table extends Component {
  render() {
    const { store } = this.props
    return (<div>
      <table>
        <thead>
          <tr>
            <td>Name:</td>
            <td>Daily salary:</td>
          </tr>
        </thead>
        <tbody>
          {store.employeesList.map((e, i) =>
            <TableRow
              key={i}
              data={e}
            />
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>TOTAL:</td>
            <td>{store.totalSum}</td>
          </tr>
        </tfoot>
      </table>
      <div>
        You have <u>{store.highEarnersCount} team members </u>that earn more that 500$/day.
      </div>
    </div>)
  }
}

Table = observer(Table)

class Controls extends Component {
  addEmployee = () => {
    const name = prompt("The name:")
    const salary = parseInt(prompt("The salary:"), 10)
    this.props.store.pushEmployee({ name, salary })
  }

  clearList = () => {
    this.props.store.clearList()
  }

  render() {
    return (<div className="controls">
      <button onClick={this.clearList}>clear table</button>
      <button onClick={this.addEmployee}>add record</button>
    </div>)
  }
}

class Index extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchText: "",
			cardTypeIds: "",
			colorIds: "",
			editionIds: "",
			rarityIds: "",
			cardData: [],
			isLoading: false,
			formAttributes: [],
		};
	}	

	componentWillMount() {
	}	

	componentDidMount() {
		this._isMounted = true;
	}	

	componentWillUnmount() {
		this._isMounted = false;
	}	

	render() {

		const data = [{
      name: 'Roy Agasthyan',
      age: 26,
      className: "red",
    },{
      name: 'Sam Thomason',
      age: 22
    },{
      name: 'Michael Jackson',
      age: 36
    },{
      name: 'Samuel Roy',
      age: 56
    },{
      name: 'Rima Soy',
      age: 28
    },{
      name: 'Suzi Eliamma',
      age: 28
    }];

		const columns = [{
		  Header: 'Name',
		  accessor: 'name'
		},{
		  Header: 'Age',
		  accessor: 'age'
		}]

		return (
			<main className="special-one-content">
				<Row>
					<Col md="12" className="search-result-section">
						{
							false &&
							<ReactTable
		            data={data}
		            columns={columns}
		            defaultPageSize = {3}
		            pageSizeOptions = {[3]}
							/>
						}				
					</Col>
				</Row>
				<hr/>
				<Row>
					<Col md="12">
		        <h1>Mobx Table</h1>
		        <Controls store={appStore} />
		        <Table store={appStore} />						
					</Col>
				</Row>
			</main>
		);
	}
}

export default Index;