import React from 'react';
import { Form } from 'react-bootstrap';
import { ButtonWithLoading, showPageDataRange } from '../helpers/common_helper';
import { observer } from 'mobx-react';

const SearchForm = observer((props) => {
	const { handleSubmit, handleChange, isLoading } = props;
	const { searchText, cardTypeIds, colorIds, editionIds, rarityIds, } = props;
	const formAttributes = props.searchFormStore.searchFormAttributes;

	return (
		<Form className="form-main-search form-inline justify-content-center" onSubmit={handleSubmit}>
			<Form.Group className="mx-sm-1">
				<Form.Control name="searchText" type="text" placeholder="Search Magic Card Names" value={searchText} onChange={handleChange}/>
			</Form.Group>
			<Form.Group className="mx-sm-1">
				<Form.Control as="select" name="cardTypeIds" value={cardTypeIds} onChange={handleChange}>
					<option value="">Any Card Type</option>
					{
						(formAttributes["card_type_ids"] || []).map((item, index) =>
							<option key={item.id} value={item.id}>{item.value}</option>
						)
					}					      
				</Form.Control>
			</Form.Group>
			<Form.Group className="mx-sm-1">
				<Form.Control as="select" name="colorIds" value={colorIds} onChange={handleChange}>
					<option value="">Any Color</option>
					{
						(formAttributes["color_ids"] || []).map((item, index) =>
							<option key={item.id} value={item.id}>{item.value}</option>
						)
					}
				</Form.Control>
			</Form.Group>
			<Form.Group className="mx-sm-1">
				<Form.Control as="select" name="editionIds" value={editionIds} onChange={handleChange}>
					<option value="">Any Edition</option>
					{
						(formAttributes["edition_ids"] || []).map((item, index) =>
							<option key={item.id} value={item.id}>{item.value}</option>
						)
					}
				</Form.Control>
			</Form.Group>
			<Form.Group className="mx-sm-1">
				<Form.Control as="select" name="rarityIds" value={rarityIds} onChange={handleChange}>
					<option value="">Any Rarity</option>
					{
						(formAttributes["rarity_ids"] || []).map((item, index) =>
							<option key={item.id} value={item.id}>{item.value}</option>
						)
					}
				</Form.Control>
			</Form.Group>																
			<Form.Group className="mx-sm-1">																		
				<ButtonWithLoading variant="outline-success" className="btn-sm" type="submit" isLoading={isLoading}>
					Search
				</ButtonWithLoading>
			</Form.Group>										
		</Form>
	);
});

export default SearchForm;