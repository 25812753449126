import React, { Component } from 'react';
import './set_name_show.scss';

import axios from 'axios'; // use later
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import queryString from 'query-string';

import TableCards from '../tables/table_cards.js';
import { showPageDataRange } from '../../helpers/common_helper';
import Paginate from '../../helpers/paginate_helper';
import SearchForm from '../../helpers/search_form_helper';

// Mobx
import SearchFormStore from '../../stores/search_form_store';

class SetNameShow extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			searchText: "",
			cardTypeIds: "",
			colorIds: "",
			editionIds: "",
			rarityIds: "",
			cardData: [],
			isLoading: false,
			setNameData: [],
			sortInfo: {},
			paginationInfo: {}
		};
	}

	componentWillMount() {
		const { match } = this.props;
		const values = queryString.parse(this.props.location.search);

		this.setState({ 
			sortInfo: {
				field: values.sort_column,
				order: values.sort_direction
			},			
			editionIds: match.params.id,
		});
		values["edition_ids"] = match.params.id;

		this.fetchSetName({ id: match.params.id });
		this.fetchCards(values);
	}	

	componentDidMount() {
		this._isMounted = true;
	}	

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchSetName = (params={}) => {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/${'api/set_names'}${params['id']}`,  {}).then(result => 
			this._isMounted && this.setState({
				editionIds: result.data.data.id
			})
		);		
	}

	fetchCards = (params={}) => {
		this.setState({ isLoading: true});

		axios.get(`${process.env.REACT_APP_API_BASE_URL}/${'api/products/search'}`,  { params }).then(result => 
			this._isMounted && this.setState({cardData: result.data, isLoading: false})
		);
	}

	handlePaginationClick = (data) => {
		const selectedVal = data.selected+1;
		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds } = this.state;

		const stringified = queryString.stringify({
			page: selectedVal
		});

		this.fetchCards({ 
			search_text: searchText,
			card_type_ids: cardTypeIds,
			color_ids: colorIds,
			edition_ids: editionIds,
			rarity_ids: rarityIds,
			page: selectedVal
		});

		this.props.history.push({
		  pathname: '/set-names/'+this.props.match.params.id,
		  search: '?'+stringified
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds } = this.state;
		const stringified = queryString.stringify({ 
			search_text: searchText, 
			card_type_ids: cardTypeIds, 
			color_ids: colorIds, 
			edition_ids: editionIds, 
			rarity_ids: rarityIds 
		});

		this.props.history.push({
		  pathname: '/products',
		  search: '?'+stringified
		});		
	}

	handleChange = (event) => {
		const { value } = event.target;
		const key = event.target.name;
		this.setState({ [key]: event.target.value });
	}

	handleSortClick = (field, order) => (event) => {
		event.preventDefault();

		this.setState({
			sortInfo: {
				field, order
			}
		});

		const { searchText, cardTypeIds, colorIds, editionIds, rarityIds, paginationInfo } = this.state;
		const params = { 
			edition_ids: editionIds,
			page: paginationInfo.current,
			sort_column: field,
			sort_direction: order,
		};
		this.fetchCards(params);

		const paramsWithoutEditionIds = {
			page: paginationInfo.current,
			sort_column: field,
			sort_direction: order,			
		};
		const stringifiedParams = queryString.stringify(paramsWithoutEditionIds);
		this.props.history.push({
		  pathname: '/set-names/'+this.props.match.params.id,
		  search: '?'+stringifiedParams
		});
	}	

	render() {
		const { cardData, isLoading, paginationInfo, sortInfo } = this.state;
		const { meta } = cardData;

		return (			
			<main className="products-content">
				<Row>
					<Col md="12">
						<SearchForm 
							searchFormStore={SearchFormStore}
							handleSubmit={this.handleSubmit} 
							handleChange={this.handleChange}
							searchText={this.state.searchText}
							cardTypeIds={this.state.cardTypeIds}
							colorIds={this.state.colorIds}
							editionIds={this.state.editionIds}
							rarityIds={this.state.rarityIds}
						/>
					</Col>
					<Col md="12" className="search-result-section">
						<div className="search-header">
							<h4>All Search</h4>
							<span>
								{meta ? meta.total_count : 0} Total Items (
										{
											meta && showPageDataRange(meta.current_page_count, meta.current_page, meta.per_page)
										}
									)
							</span>
						</div>
						<div className="search-content">
							<div className="table-section">
								<TableCards handleSortClick={this.handleSortClick} sortInfo={sortInfo} cardData={cardData} isLoading={isLoading}/>							
							</div>
							<Paginate pageClassName="page-item" pageLinkClassName="page-link" handlePageClick={this.handlePaginationClick} forcePage={paginationInfo.current} pageCount={meta && meta.total_pages} />
						</div>
					</Col>
				</Row>
			</main>
		);
	}
}

export default SetNameShow;