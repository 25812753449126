import React, { Component } from 'react';
import './index.scss';

import { Row, Col } from 'react-bootstrap';

class Donate extends Component {

	render() {
		return (
			<main className="donate-content">
				<Row className="page-header">
					<Col className="col-md-12">
						<h1 className="page-title-center">Why Donate?</h1>
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="col-md-10">
						<p>OpenMagic is fully funded by me and nobody else. If you like what I have done, you can donate some ETH to my wallet and fund my server cost and pizza as well. I would very much appreciate it. :)</p>
						<p className="text-center">Below is my ETH address</p>
						<p className="text-center">
							<code>0xC02cCDfCF3A258B0FfAB0a4e3B02677A31BbA6D5</code>
						</p>						
					</Col>
				</Row>
			</main>
		);
	}
}

export default Donate;