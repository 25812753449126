import { observable, action, decorate } from "mobx";

import apiAgent from "../api/api_agent";
import commonStore from "./common_store";

class AuthStore {
	isLoading = false;
	isLoadingRegister = false;
	isLoadingConfirmation = false;

	user = {
		email: "",
		password: "",
	};

	userRegister = {
		email: "",
		password: "",
		passwordConfirmation: "",
	};

	userConfirmation = {
		confirmationToken: "",
	}

	// constructor() {
 //    reaction(
 //      () => this.userConfirmation,
 //      (userConfirmation, reaction) => {
 //        if (userConfirmation) {
 //        	this.fetchProduct();

 //        }
 //      }
 //    );		
	// }	

	setUserFields(name, value) {
		this.user[name] = value;
	}

	login() {
		this.isLoading = true;

		return apiAgent.Auth.login(this.user.email, this.user.password)
			.then(
				action(result => {
					commonStore.setToken(result.headers["auth-token"]);
				})
			)
			.finally(
				action(() => {
					this.isLoading = false;
					this.user = { email: "", password: "" };
				})
			);
	}

	register() {
		this.isLoadingRegister = true;

		return apiAgent.Auth.register(
			this.user.email,
			this.user.password,
			this.user.passwordConfirmation
		)
		.finally(
			action(() => {
				this.isLoadingRegister = false;
				this.userRegister = {
					email: "",
					password: "",
					passwordConfirmation: "",
				};
			})
		);
	}

	setConfirmationToken(confirmationToken) {
		this.userConfirmation['confirmationToken'] = confirmationToken;	
	}

	confirmUser() {
		this.isLoadingConfirmation = true;

		return apiAgent.Auth.confirmation(
			this.userConfirmation.confirmationToken,
		)
		.finally(
			action(() => {
				this.isLoadingConfirmation = false;
				this.userConfirmation = {
					confirmationToken: "",
				}
			})
		);
	}
}

decorate(AuthStore, {
	isLoading: observable,
	isLoadingRegister: observable,
	isLoadingConfirmation: observable,
	user: observable,
	login: action,
});

export default AuthStore;
