import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class PrivateRoute extends React.Component {
  render() {
    const { commonStore, rootStore, ...restProps } = this.props;
    if (commonStore.isAuthenticated) return <Route {...restProps} />;

    rootStore.signInStore.setFlashMessages({ messages: 'Please Login...' });
    return <Redirect to={{
  							pathname: "/users/sign-in",
  							state: { from: restProps.location }
  						}} />;
  }
}

export default inject('commonStore', 'rootStore')(observer(PrivateRoute));