import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class SpecialRoute extends React.Component {
  render() {
    const { commonStore, rootStore, ...restProps } = this.props;
    if (!commonStore.isAuthenticated) return <Route {...restProps} />;

    rootStore.dashboardStore.setFlashMessages({ messages: 'You are already login!' });
    return <Redirect to={{
  							pathname: "/dashboard",
  							state: { from: restProps.location }
  						}} />;
  }
}

export default inject('commonStore', 'rootStore')(observer(SpecialRoute));