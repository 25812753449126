import axios from "axios"; // use later

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const superagent = axios;

const SetNames = {
	get: () =>
		superagent
			.get(`${API_BASE_URL}${"/api/set_names"}`)
			.then(result => result.data),
};

const Products = {
	getLatest: () =>
		superagent.get(`${API_BASE_URL}${"/api/products/latest_cards"}`),
	getTopGainers: () =>
		superagent.get(`${API_BASE_URL}${"/api/products/top_gainers"}`),
	show: id => superagent.get(`${API_BASE_URL}${"/api/products/"}${id}`),
	getRecommended: id =>
		superagent.get(
			`${API_BASE_URL}${"/api/products/"}${id}${"/recommended_products"}`
		),
};

const TcgGroups = {
	getAll: () => superagent.get(`${API_BASE_URL}${"/api/tcg_groups"}`),
};

const Auth = {
	login: (email, password) =>
		superagent.post(`${API_BASE_URL}${"/api/users/sessions"}`, {
			user: { email, password },
		}),
	register: (email, password, passwordConfirmation) =>
		superagent.post(`${API_BASE_URL}${"/api/users"}`, {
			user: { email, password, password_confirmation: passwordConfirmation },
		}),
	confirmation: (confirmation_token) =>
		superagent.get(`${API_BASE_URL}${"/api/users/confirmation"}`, {
			params: { confirmation_token }
		})
};

export default {
	SetNames,
	Products,
	TcgGroups,
	Auth,
};
